nav.MuiList-root {
    .MuiListItemIcon-root {
        min-width: 40px;
    }

    .MuiListItemButton-root:hover, .active {
        background-color: #092948;

        .MuiSvgIcon-root, .MuiTypography-root {
            color: #FFF;
        }
    }
}
